import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { HeadingContainer } from '../components/common/typography'
import bp from '../components/common/breakpoints'
import AppLayout from '../components/layouts/appLayout'
import CaseStudiesSVG from '../images/icons/resources/glasses.svg'
import BlogSVG from '../images/icons/resources/blogger.svg'
import EventsSVG from '../images/icons/resources/calendar-event.svg'
import CircleSVG from '../images/icons/resources/circle.svg'
import { WHITE_COLOR } from '../components/common/color'
import SEO from '../components/seo'

const resources = [
  {
    title: 'Case Studies',
    slug: 'case-studies',
    icon: <CaseStudiesSVG />,
  },
  {
    title: 'Blog',
    slug: 'blog',
    icon: <BlogSVG />,
  },
  {
    title: 'Events',
    slug: 'events',
    icon: <EventsSVG />,
  },
]

const Container = styled.div`
  display: grid;
  row-gap: 2rem;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: 0;
  padding-bottom: 6rem;
  box-sizing: border-box;

  ${bp[2]} {
    width: 90%;
  }

  ${bp[3]} {
    width: 100%;
    max-width: 1200px;
  }
`

const Card = styled(Link)`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: 1fr;
  column-gap: 1rem;
  background: linear-gradient(
    180deg,
    rgba(234, 234, 234, 0) 0%,
    rgba(234, 234, 234, 0.4) 95.83%
  );
  border-radius: 100px 0px 0px 100px;
  box-sizing: border-box;

  ${bp[0]} {
    grid-template-columns: 220px 1fr;
    grid-template-rows: 180px;
  }

  ${bp[1]} {
    padding: 0;
  }

  ${bp[2]} {
    column-gap: 2rem;
  }

  ${bp[3]} {
    column-gap: 4rem;
  }
`

const IconContainer = styled.div`
  align-self: center;
  justify-self: center;
  position: relative;

  svg:first-of-type {
    background: ${WHITE_COLOR};
    border-radius: 100px;
    padding: 0.5rem;

    ${bp[0]} {
      box-shadow: 10px 10px 20px rgba(200, 180, 156, 0.14),
        -10px -10px 20px #ffffff;
    }
  }

  svg:last-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const ResourceLink = styled.span`
  align-self: center;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;

  ${bp[0]} {
    font-size: 1.5rem;
  }
`

const ResourcesPage = () => {
  return (
    <AppLayout>
      <SEO title="Resources" canonical="resources" />
      <HeadingContainer title="You can always reach us" subtitle="Resources" />
      <Container>
        {resources.map((resource) => (
          <Card key={resource.slug} to={`/${resource.slug}`}>
            <IconContainer>
              <CircleSVG />
              {resource.icon}
            </IconContainer>
            <ResourceLink>{resource.title}</ResourceLink>
          </Card>
        ))}
      </Container>
    </AppLayout>
  )
}

export default ResourcesPage
